import styled from "@emotion/styled";
export const StyledCardComponent = styled.div`
  display: flex;
  height: 100%;
  border-radius: 40px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background-color: white;
  gap: 60px;

  .tab-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .asset-inner {
      img {
        ${({ cardImageHeight })=>cardImageHeight ? `height: ${cardImageHeight}` : `height: 28px`};
        width: auto;
      }
    }

    .carousel-arrows {
      display: flex;
      gap: 16px;

      svg {
        width: 40px;
        height: 40px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .stats-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    width: 100%;

    .stats {
      flex-basis: 0;
      flex-grow: 1;
      display: flex;

      .core-title {
        font-weight: 600;
      }

      .vertical-line {
        width: 1px;
        height: 100%;
        background-color: black;
      }
    }
  }

  .testimonial-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 70px;

    .testimonial {
      padding: 0;

      .content {
        gap: 36px;
        width: 100%;

        .author {
          .asset-inner {
            height: 40px;
            width: 40px;
          }

          .author-details-container {
            font-size: 0.875rem;

            .author-name {
              font-weight: 600;
            }

            .core-title {
              font-size: 0.875rem;
            }
          }
        }

        .quote {
          font-size: 1.125rem;
          line-height: 150%;
          margin-bottom: 0;
        }
      }
    }
  }
`;
